import React, { useState, useEffect } from 'react'
import { animated } from 'react-spring'

import Grid from '@material-ui/core/Grid';
import { Text } from '../../../components/ui-kit/Typo'

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next'
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.primary,
  },
  pkg: {
      borderRadius: '10px',
      "&:hover": {
          backgroundColor: theme.palette.action.hover
      }
  },
  btn: {
    margin: 'auto 0',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end'
    },
  }
}));

export function Step1(props) {

  const classes = useStyles();
  const [pkgs, setpkgs] = useState(() => {
    return props.place.placePakage;
  })

  const [p, setP] = useState(props.request.pakage);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setP(pkgs[newValue]);
  };
  // const stepClass = `${classes.root} step step2`;

  // get pkg id from query string and go to next step
  const [value, setValue] = useState(props.request.pakage.id != 0 ? pkgs.map(function(e) { return e.id; }).indexOf(props.request.pakage.id) : 0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    let queryValue = queryParams.get('pid');
    for(const index in pkgs) {
      if (pkgs[index].id === parseInt(queryValue)) {
        onHandleStep(pkgs[index]);
      }
    }
  }, []);

  let {style, onHandleStep, place, request} = props;

  const { t } = useTranslation(['iframe', 'listing']);

  return (
    <animated.div style={{ ...style, padding: '16px' }}>
      <Grid container spacing={3} justify="space-around" alignItems="center">

        <Grid item md={12}>
          <Text type="subtitle" uppercase>{t('event.book.step3.choose_pkg')}</Text>
        </Grid>

        <Grid item md={12} xs={12} className={'scroll'}>
          <Grid container spacing={3}>
            {place.placePakage.filter((obj) => {
              return obj.pkgType != 'S';
            }).map((pkg, key) => {
              if (!pkg.active)
                return (null);
              return (
                <Grid item xs={12}>
                  <Grid container className={classes.pkg} spacing={3}>
                    <Grid item xs={12} md={10} key={key} onClick={() => onHandleStep(pkg)}>
                      <Grid container  spacing={0}>
                        <Grid item xs={10} md={10}>
                            <Text type="title3" component="h2" semibold className="sb-blue1">
                                {pkg.title}
                            </Text>
                        </Grid>
                        <Grid item xs={12}>
                          <Text type="body1" component="h4" className="sb-text body1">
                            {pkg.type.length == 2 ? t('listing:profile.pakage.lessons_both') : pkg.type.indexOf('snowboard') > 0 ? t('listing:profile.pakage.lessons_snow') : t('listing:profile.pakage.lessons_ski')}
                            {pkg.spots} {pkg.spots == 1 ? t('listing:profile.pakage.person') : t('listing:profile.pakage.persons')}
                            {pkg.availability} {pkg.availability == 1 ? t('listing:profile.pakage.lesson') : t('listing:profile.pakage.lessons')}
                            {pkg.timeUnit} {pkg.timeUnit == 1 ? t('listing:profile.pakage.hour') : t('listing:profile.pakage.hours')}
                            <br/>
                            {pkg.locations.length > 1 && <>{t('listing:profile.pakage.location')}: {pkg.locations.map((l, i) => {return `${l.name}, ${l.county.name} ${pkg.locations.length > 1 && i+1 < pkg.locations.length ? ' / ' : ''}`})}</>}
                            <br/>
                            {(pkg.price > 0 &&
                                <>
                            {t('listing:profile.pakage.price')} <span className="sb-text regular">{pkg.price}
                                  {/*{pkg.place.placeInfo.currency}*/}
                                  {pkg.pkgPrice ? t('listing:profile.pakage.price_pkg') : t('listing:profile.pakage.price_pers')}</span>
                                </>
                            )}
                          </Text>
                        </Grid>
                        <Grid item md={12}>
                          <Text type="body" component="p" >{pkg.abs && `"`}{pkg.abs}</Text>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={'auto'} md={2} className={classes.btn}>
                      <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                        <Grid>
                          <Button variant="outlined" color="primary" onClick={() => onHandleStep(pkg)}>
                            {t('event.book.step3.book')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
            )})}
          </Grid>
        </Grid>
      </Grid>
    </animated.div>
  )
}

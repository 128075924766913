import React, { useState, useEffect } from 'react'
import { animated } from 'react-spring'

import Grid from '@material-ui/core/Grid';
import { Text } from '../../../components/ui-kit/Typo'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { useTranslation } from 'react-i18next'

export function Step9(props){

  let {style, onHandleStep, request} = props;
  const [data, setData] = useState({title: request.title, email: request.email, phone: request.phone, attendees: request.attendees, info: request.info});
  const [error, setError] = useState({title: false, email: false, phone: false, attendees: false, info: false});
  const disabled =  !/^[A-Za-z0-9-. ]{3,54}$/.test(data.title) ||
                    !/^[A-Za-z0-9._%+-]{2,54}@[A-Za-z0-9.-]{2,54}\.[A-Za-z]{2,7}$/.test(data.email) ||
                    !/^\+?[0-9() ]{10,16}$/.test(data.phone) || error.attendees || error.info ||
                    !/^[A-Za-z0-9-.,; ]{3,254}$/.test(data.address) || !/^[A-Za-z0-9-.,; ]{2,254}$/.test(data.city);

  const { t } = useTranslation('iframe');

  useEffect(() => {
    setError({...error, title: !/^[A-Za-z0-9-. ]{3,54}$/.test(data.title)})
  }, [data.title])

  useEffect(() => {
    setError({...error, email: !/^[A-Za-z0-9._%+-]{2,54}@[A-Za-z0-9.-]{2,54}\.[A-Za-z]{2,7}$/.test(data.email)})
  }, [data.email])

  useEffect(() => {
    setError({...error, phone: !/^\+?[0-9() ]{10,16}$/.test(data.phone)})
  }, [data.phone])

  useEffect(() => {
    setError({...error, attendees: (data.attendees === "" || !data.attendees || data.attendees === 0)})
  }, [data.attendees])

  useEffect(() => {
    setError({...error, info: data.info && data.info.length > 254})
  }, [data.info])

  useEffect(() => {
    if (request.payMethod === 'online')
      setError({...error, address: data.address && data.address.length > 254})
  }, [data.address])

  useEffect(() => {
    if (request.payMethod === 'online')
      setError({...error, city: data.city && data.address.length > 254})
  }, [data.city])

  return(
    <animated.div style={{ ...style, padding: '16px' }}>
      <Grid container spacing={3} justify="space-around" alignItems="center">

        <Grid item md={4}>
          <Text type="subtitle" uppercase>{t('event.book.step7.contacts')}</Text>
        </Grid>

        <Grid item md={8} xs={12}>
          <Grid container spacing={3} justify="space-around" alignItems="center">

            <Grid item xs={12}>

              <form noValidate>
                <Grid container spacing={3} justify="space-around" alignItems="center">
                  <Grid item md={6} xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="name"
                      autocomplete="name"
                      label={t('event.book.step7.name')}
                      onChange = {(event, newValue) => {
                        var newVal = event.target.value;
                        if (newVal.length < 54)
                          setData(prevValues=>({...prevValues, title:newVal}))
                      }}
                      value={data.title}
                      error={error.title}
                      helperText={error.title ? `${t('event.book.step7.name_helperText')}` : ''}
                    />
                    <TextField
                      required
                      fullWidth
                      id="email"
                      type="email"
                      autocomplete="email"
                      label={t('event.book.step7.email')}
                      onChange = {(event,newValue) => {
                        var newVal = event.target.value;
                        setData(prevValues=>({...prevValues, email:newVal}))
                      }}
                      value={data.email}
                      error={error.email}
                      helperText={error.email ? `${t('event.book.step7.email_helperText')}` : ''}
                    />
                    <TextField
                      required
                      fullWidth
                      id="tel"
                      type="tel"
                      autocomplete="tel"
                      label={t('event.book.step7.phone')}
                      onChange = {(event,newValue) => {
                        var newVal = event.target.value;
                        setData(prevValues=>({...prevValues, phone:newVal}))
                      }}
                      value={data.phone}
                      error={error.phone}
                      helperText={error.phone ? `${t('event.book.step7.phone_helperText')}` : ''}
                    />
                    {request.payMethod == 'online' &&
                      <TextField
                        required
                        fullWidth
                        id="address"
                        type="text"
                        autocomplete="address"
                        label={t('event.book.step7.address')}
                        onChange = {(event,newValue) => {
                          var newVal = event.target.value;
                          setData(prevValues=>({...prevValues, address:newVal}))
                        }}
                        value={data.address}
                        error={error.address}
                        helperText={error.address ? `${t('event.book.step7.address_helperText')}` : ''}
                      />}
                    {request.payMethod == 'online' &&
                      <TextField
                        required
                        fullWidth
                        id="city"
                        type="text"
                        autocomplete="city"
                        label={t('event.book.step7.city')}
                        onChange = {(event,newValue) => {
                          var newVal = event.target.value;
                          setData(prevValues=>({...prevValues, city:newVal}))
                        }}
                        value={data.city}
                        error={error.city}
                        helperText={error.city ? `${t('event.book.step7.city_helperText')}` : ''}
                      />}
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="attendees"
                      type="number"
                      label={t('event.book.step7.attendees')}
                      onChange = {(event, newValue) => {
                        var newVal = event.target.value;
                        if (newVal > -1 && newVal <= 25)
                          setData(prevValues=>({...prevValues, attendees:newVal}))
                      }}
                      value={data.attendees}
                      error={error.attendees}
                      helperText={error.attendees ? `${t('event.book.step7.attendees_helperText')}` : ''}
                    />
                    <TextField
                      label={t('event.book.step7.notes')}
                      placeholder={t('event.book.step7.remarks')}
                      fullWidth
                      multiline
                      rows="4"
                      onChange = {(event, newValue) => {
                        var newVal = event.target.value;
                        if(newVal.length < 255)
                          setData(prevValues=>({...prevValues, info:newVal}))
                      }}
                      value={data.info}
                      error={error.info}
                      helperText={error.info ? `${t('event.book.step7.remarks_helperText')}` : ''}/>
                  </Grid>
                </Grid>
              </form>

            </Grid>

            <Grid item xs={12} className={"text-center"}>
              <Button variant="outlined" color="primary" onClick={() => onHandleStep(data)} disabled={disabled}>
                Next >
              </Button>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </animated.div>
  )
}

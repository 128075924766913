import React, { useState, useEffect } from 'react'
import { animated } from 'react-spring'
import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import { Papir } from '../../../components/ui-kit/Papir'
import { Text } from '../../../components/ui-kit/Typo'
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as AMIcon } from './assets/ante-meridiem.svg'
import { ReactComponent as PMIcon } from './assets/post-meridiem.svg'
import { ReactComponent as FULLIcon } from './assets/full.svg'
import { makeStyles } from '@material-ui/core/styles'

import moment from 'moment';
import _ from 'lodash';

import { useTranslation } from 'react-i18next'

const DATE_FORMAT = 'DD.MM';
const TIME_FORMAT = 'HH:mm';

const useStyles = makeStyles((theme) => ({
    svgicon: {
      width: '100px',
      height: '100px',
      fill: theme.palette.text.secondary,
      [theme.breakpoints.down('xs')]: {
        width: '75px',
        height: '75px'
      }
    },
    active: {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main
    }
  }));

export function Step7(props){
  let {style, onHandleStep, request, place} = props;
  let {start, end} = props.request;
  const classes = useStyles();

  let [free, setFree] = useState([]);
  let [loading, setLoading] = useState(false);
  let [selected, setSelected] = useState(props.request.requestTimestamps);

  const { t } = useTranslation('iframe');
  useEffect(() => {
    setLoading(true);
    axios.post(process.env.REACT_APP_BUDDY_SERVICE + '/place/' + props.place.link + '/free-spots', {startDate: start, endDate: end, eventType: request.type})
    .then(res => {
      var occurrenceDay = function(occurrence){
          return moment(occurrence).startOf('day').format();};

      var groupToDay = function(group, day){
          return {
              day: day,
              times: group}};

      var result = _.chain(res.data)
          .groupBy(occurrenceDay)
          .map(groupToDay)
          .sortBy('day')
          .value();

      setFree(result);
      setLoading(false);
    },
    err => {
      setLoading(false);
    });
  }, []);

  const setHour = hour => {
    let dates = [...selected]
    const index = dates.indexOf(hour);
    if (index !== -1){
      dates.splice(index, 1);
    }
    else{ //if (request.pakage.availability > dates.length){
      dates.push(hour);
      // if (request.pakage.availability == dates.length)
      //   onHandleStep(dates);
    }
    setSelected(dates);
  }

  return (
    <animated.div style={{ ...style, padding: '16px' }}>
      <Grid container spacing={3} justify="space-around" alignItems="center" className={'step6'}>

        <Grid item md={4}>
          <Text type="subtitle" uppercase>{t('event.book.step6.favorite_time')}</Text>
        </Grid>

          {loading ?
            <Grid item md={8} xs={12}>
              <Grid container justify="space-around" alignItems="center">
                <Grid item>
                  <CircularProgress color="secondary"/>
                </Grid>
              </Grid>
            </Grid>
            :
            <Grid item md={8} xs={12}>{free.length > 0 ?
              <Grid container spacing={3} justify="space-around" alignItems="center">
                <Hidden smDown>
                  <Grid container direction="row" className={'date-row'}>
                    <Grid item xs={2}>
                      <Text type="body">{t('event.book.step6.date')}</Text>
                    </Grid>
                    <Grid item xs={10}>
                      <Text type="body">{t('event.book.step6.hour')}</Text>
                    </Grid>
                  </Grid>
                </Hidden>
                <Grid item xs={12} className={'scroll'}>
                  {free.map((key, index) =>
                      <Grid container direction="row" className={'date-row'} key={index}>
                        <Grid item xs={12} md={2}>
                          <Text type="subtitle3">{moment(key.day).format(DATE_FORMAT)}</Text>
                        </Grid>

                        <Grid item xs={12} md={10}>
                          <Grid container justify="flex-start" direction="row">
                            {key.times.map((key, index) =>{
                              let includes = selected.includes(key);
                              return <Button variant={includes ? 'contained' : 'outlined'} color="secondary" key={index} className={`hour ${includes ? 'selected' : ''}`} onClick={() => setHour(key)}>
                                {moment(key).format(TIME_FORMAT)}
                              </Button>
                            })}
                          </Grid>
                          </Grid>
                      </Grid>
                    )}
                </Grid>

                <Grid item xs={12} className={"text-center"}>
                  <Button variant="outlined" color="primary" onClick={() => onHandleStep(selected)}>
                    Next >
                  </Button>
                </Grid>
              </Grid>
              :
              <Grid container
                spacing={1}
                direction="row"
                justify="space-around"
                alignItems="center"
              >
                <Grid item style={{cursor: 'pointer'}} onClick={() => onHandleStep('AM')} md={4} xs={6}>
                  <Papir align="center">
                    <AMIcon className={`${request.prefersTime === 'AM' && classes.active} ${classes.svgicon}`} />
                    <Text type="subtitle3">{t('event.book.step6.morning')}</Text>
                  </Papir>
                </Grid>
                <Grid item style={{cursor: 'pointer'}} onClick={() => onHandleStep('PM')} md={4} xs={6}>
                  <Papir align="center">
                    <PMIcon className={`${request.prefersTime === 'PM' && classes.active} ${classes.svgicon}`} />
                    <Text type="subtitle3">{t('event.book.step6.afternoon')}</Text>
                  </Papir>
                </Grid>
              </Grid>}
            </Grid>
          }

      </Grid>
    </animated.div>
  )
}

import React from 'react'
import { Grid } from '@material-ui/core'
import { Text } from '../../../components/ui-kit/Typo'
import * as moment from 'moment'

import { useTranslation } from 'react-i18next'

const DATE_FORMAT = 'DD.MM';
const TIME_FORMAT = 'HH:mm';

export default function Info({ place }) {

  const { t } = useTranslation('listing');

  return (
      <Grid container spacing={1}>
          <Grid item xs={12}>
            <Text type="subtitle3" className="sb-blu">
                {t('profile.info.contact')}
            </Text>
            <Text type="body">
              {t('profile.info.phone')} <a href={`tel:${place.placeInfo.phone}`}>{place.placeInfo.phone}</a>
              <br/>
              {t('profile.info.web')} <a href={place.placeInfo.website} target="_blank">{place.placeInfo.website}</a>
              <br/>
              {t('profile.info.terms')} <a href={place.placeInfo.terms} target="_blank">{place.placeInfo.terms}</a>
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text type="subtitle3" className="sb-blu">
                {t('profile.info.schedule')}
            </Text>
            <Text type="body">
                 {t('profile.info.schedule_date')} {moment(place.placeInfo.startTime).format(DATE_FORMAT)}, {t('profile.info.schedule_hour')} {moment(place.placeInfo.startTime).format(TIME_FORMAT)} - {moment(place.placeInfo.closeTime).format(TIME_FORMAT)}
            </Text>
          </Grid>
      </Grid>
  )
}

import React, { useEffect } from 'react'
import { animated } from 'react-spring'

import Grid from '@material-ui/core/Grid';
import { Papir } from '../../../components/ui-kit/Papir'
import { Text } from '../../../components/ui-kit/Typo'

import { ReactComponent as SkiIcon } from './assets/skiing.svg'
import { ReactComponent as SnowboardIcon } from './assets/snowboard.svg'

import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    svgicon: {
      width: '100px',
      height: '100px',
      fill: theme.palette.text.secondary,
      [theme.breakpoints.down('xs')]: {
        width: '75px',
        height: '75px'
      }
    },
    active: {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main
    }
  }));

export function Step2(props){

  let {style, onHandleStep, place, request} = props;
  const classes = useStyles();

  const { t } = useTranslation('iframe');

  useEffect(() => {
    // if(!(place.placeInfo.ski && place.placeInfo.snowboard))
    //   place.placeInfo.ski ? onHandleStep('ski') : onHandleStep('snowboard');
    // if(request.pakage.type.length === 1)
    //   onHandleStep(request.pakage.type[0])
  },[])

  return(
    <animated.div style={{ ...style, padding: '16px' }}>
      <Grid container
        spacing={3}
        justify="space-around"
        alignItems="center">
        <Grid item md={4}>
          <Text type="subtitle" uppercase>{t('event.book.step1.category')}</Text>
        </Grid>
        <Grid item md={8} xs={12}>
          <Grid container
            spacing={1}
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item style={{cursor: 'pointer'}} onClick={() => onHandleStep('ski')} md={4} xs={12}>
              <Papir align="center">
                <SkiIcon className={`${request.type === 'ski' && classes.active} ${classes.svgicon}`} />
                <Text type="subtitle3">{t('event.book.step1.ski')}</Text>
              </Papir>
            </Grid>
            <Grid item style={{cursor: 'pointer'}} onClick={() => onHandleStep('snowboard')} md={4} xs={12}>
              <Papir align="center">
                <SnowboardIcon className={`${request.type === 'snowboard' && classes.active} ${classes.svgicon}`} />
                <Text type="subtitle3">{t('event.book.step1.snowboard')}</Text>
              </Papir>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </animated.div>
  )
}

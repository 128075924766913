import React, { useState, useEffect } from 'react';
import { animated } from 'react-spring';
import axios from 'axios';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Text } from '../../../components/ui-kit/Typo';
import Terms from './terms/Terms'
import { useTranslation } from 'react-i18next'

const DATE_FORMAT = 'DD MMM YYYY';

export function Step10(props) {
  let { style, onHandleStep, place, request } = props;
  const [check, setCheck] = useState({ terms: false, agreement: false });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errcount, setErrcount] = useState(0);

  const { t } = useTranslation('iframe');

  const handleChange = event => {
    setCheck({ ...check, [event.target.value]: event.target.checked });
  };

  const submitRequest = () => {
    // set preffered time here
    let info = request.prefersTime ?
      (request.info + " " + t('event.book.step6.favorite_time') + ': ' + (request.prefersTime == 'AM' ? t('event.book.step6.morning') : request.prefersTime == 'PM' ? t('event.book.step6.afternoon') : t('event.book.step6.full'))) : request.info;
    setLoading(true);
    axios.post(process.env.REACT_APP_BUDDY_SERVICE + '/place/' + props.place.link + '/package/book', {...request, info: info, pakage: {...request.pakage, locations: undefined, place: undefined}})
      .then(res => {
        onHandleStep(res.data);
      },
        err => {
          setLoading(false);
          setError(err.response.data);
          setErrcount(errcount + 1);
        });
  }

  useEffect(() => {
    if (check.terms)// && check.agreement)
      submitRequest();
  }, [check])

  // const termsLabel = (<span>{t('event.book.step8.terms_conditions')}</span>)
  const termsLabel = (<span>{t('event.book.step8.terms_label1')} <a href={place.placeInfo.terms} target="_blank" >{t('event.book.step8.terms_label2')}</a> </span>)
  const agreementLabel = (<Terms
    place={place}
    request={request}
    accept={() => setCheck({ ...check, agreement: true })} />)

  return (
    <animated.div style={{ ...style, padding: '16px'}}>
      <Grid container spacing={3} justify="space-around" alignItems="center">

        <Grid item md={4}>
          <Text type="subtitle" uppercase>{t('event.book.step8.everything_right')}</Text>
        </Grid>

        <Grid item md={8} xs={12}>
          <Grid container spacing={3} justify="space-around" alignItems="center">

            <Grid item xs={12} >
              <Grid container spacing={1} justify="space-around" alignItems="center">
                <Grid item xs={12}>
                  <Text type="body" style={{ fontSize: '18px' }}>
                    {t('event.book.step8.dear')} {request.title}
                    <br /><br />
                    {t('event.book.step8.thank_you')}
                    <br /><br />
                    {t('event.book.step8.please_confirm')}
                    <br />
                    {t('event.book.step8.package')} {request.pakage.title}
                    <br />
                    {t('event.book.step8.person_no')} {request.attendees} {request.attendees === '1' ? `${t('event.book.step8.person')}` : `${t('event.book.step8.persons')}`}
                    <br />
                    {t('event.book.step8.date')} {moment(request.start).format(DATE_FORMAT)} {moment(request.start).isBefore(moment(request.end)) && <span>- {moment(request.end).format(DATE_FORMAT)}</span>}
                    <br />
                    {request.prefersTime && ` ${t('event.book.step8.prefersTime')} ${request.prefersTime == 'AM' ? t('event.book.step6.morning') : request.prefersTime == 'PM' ? t('event.book.step6.afternoon') : t('event.book.step6.full')}`}
                    <br />
                    {request.info && ` ${t('event.book.step8.details')} ${request.info}`}
                    <br />
                    {request.pakage.price > 0 && ` ${t('event.book.step8.price')} ${request.price}${place.placeInfo.currency}`}
                    <br /><br />
                    {t('event.book.step8.contact_details')} <span color="secondary">{request.phone} </span> / <span color="secondary">{request.email}</span>
                    {request.payMethod === 'online' && <br />}
                    {request.payMethod === 'online' && ` ${t('event.book.step8.billing')} ${request.address}, ${request.city}`}
                    <br />
                    <Grid>
                      <Checkbox
                          checked={check.terms}
                          onChange={handleChange}
                          value="terms"
                          color="secondary"
                          style={{ marginLeft: 0, paddingLeft: 0 }}
                      />
                      {termsLabel}
                    </Grid>
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ minHeight: '50px', display: 'inline-block' }} >
          {loading ?
              <CircularProgress color="secondary" /> :
              <div>{errcount > 30 ? '' :
                  <div>
                    {(check.terms) ? <Button variant="outlined" color="primary" onClick={() => submitRequest()} disabled={!check.terms}>
                      {t('event.book.step8.confirmation')}
                    </Button> : ''}
                  </div>}
              </div>}
          {error ?
              <Text type="body" color="error">{t('event.book.step8.err')} <br/>{error.message}</Text> : ''}
        </Grid>
      </Grid>
    </animated.div>
  )
}

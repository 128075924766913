import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';
import { useParams, useLocation } from 'react-router-dom'

export default function Seo(props) {
  let { title, description, image } = props;

  let { lang } = useParams();
  const location = useLocation();

  useEffect(() => {
    console.log(location)
  }, [])

  return(
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />

      <meta property="description" content={description} />
      <meta property="og:description" content={description} />

      <link rel="alternate" hreflang={lang == 'ro' ? 'en' : 'ro'} href={`https://snowbuddy.app/${lang == 'ro' ? 'en' : 'ro'}${location.pathname.slice(3)}`} />

      {image && <meta name="og:image" content={`https://snowbuddy.app${image}`} />}
      {image && <meta name="og:image_secure" content={`https://snowbuddy.app${image}`} />}

    </Helmet>
  )
}

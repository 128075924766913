import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment';
import { useTranslation } from 'react-i18next'

import Book from './Book'
import StepError from '../../../iframe/event/book/StepError';

const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export default function EventBookPage(props){

  const { t } = useTranslation('iframe');
  let { pakage, place, bookPkg, setSelectedPackage } = props;
  const ref = useRef(null);

  const [step, setStep] = useState(2);
  const [maxStep, setMaxStep] = useState(11);
  const [error, setError] = useState({});
  const [request, setRequest] = useState({type: '',
                                          start: moment().hour(9).minute(0).second(0),
                                          end: moment().hour(10).minute(0).second(0),
                                          pakage: pakage,
                                          requestTimestamps: [],
                                          prefersTime: '',
                                          // clientPkg
                                          level: '',
                                          attendees: 1,
                                          info: '',
                                          // client
                                          email: '',
                                          phone: '',
                                          info: '',
                                          address: '',
                                          city: '',
                                          attendees: 1,
                                          price: 0
                                        });

  useEffect(() => {
    if (ref.current)
      ref.current.scrollIntoView({behavior: 'smooth'})
  },[pakage])

  const nextStep = () => {
    if (ref.current)
      ref.current.scrollIntoView({behavior: 'smooth'})
      //pkgPrice e un flag care indica daca pretul e pe pachet ssau pe persoana
    if((step === 3 && request.pakage.pkgPrice) || (step === 2 && request.pakage.locations.length < 2))
      setStep(step + 2)
    else
      (step < maxStep) && setStep(step + 1);
  }

  const prevStep = () => {
    if (ref.current)
      ref.current.scrollIntoView({behavior: 'smooth'})
    let localStep = step;
    if(step === 3 && request.pakage.type.length < 2)
      setSelectedPackage(false);
    else if((step === 4 && request.pakage.locations.length < 2) && (request.pakage.type.length < 2))
      setSelectedPackage(false);
    else if (step === 4 && request.pakage.locations.length < 2)
      localStep = step - 2
    else if((step === 5 && request.pakage.pkgPrice) && (request.pakage.locations.length < 2) && (request.pakage.type.length < 2))
      localStep = step - 4;
    else if((step === 5 && request.pakage.pkgPrice) && (request.pakage.locations.length < 2))
      localStep = step - 3;
    else if(step === 5 && request.pakage.pkgPrice)
      localStep = step - 2;
    else if (step > 1 && step != maxStep)
      localStep = step - 1;
    else if(step === maxStep)
      setSelectedPackage(false);
    // if (localStep == 1 && redirect != undefined)
    //   window.location.replace(redirect);
    setStep(localStep)
  }

  const skipNextStep = () => {
    setStep(step + 2);
  }

  const onHandleStep = (data) => {
    switch(step) {
      case 1:
          if(data.type.length > 1) {
              setRequest({...request, pakage: data, price: data.price});
              nextStep();
          }
          else {
              setRequest({...request, pakage: data, price: data.price, type: data.type[0]});
              skipNextStep();
          }
        break;

      case 2:
        setRequest({...request, type: data});
        nextStep();
        break;

      case 3:
        setRequest({...request, location: data});
        nextStep();
        break;

      case 4:
        setRequest({...request, attendees: data, price: request.pakage.pkgPrice ? request.pakage.price : (request.pakage.price * data)});
        nextStep();
        break;

      case 5:
        setRequest({...request, level: data});
        nextStep();
        break;

      case 6:
        let start = moment(data.start).hour(moment(place.placeInfo.startTime).hour()).format(DATETIME_FORMAT);
        let end = moment(data.end ? data.end : data.start).hour(moment(place.placeInfo.closeTime).hour()).format(DATETIME_FORMAT);
        if (request.start || request.end)
          setRequest({...request, start: start, end: end});

        nextStep();
        break;

      case 7:
        if(data === 'AM' || data === 'PM')
          setRequest({...request, prefersTime: data});
        else{
          let dates = [];
          data.forEach(time => dates.push(moment(time).format(DATETIME_FORMAT)));
          setRequest({...request, requestTimestamps: data, requestDates: dates.toString()});
        }
        nextStep();
        break;

      case 8:
        setRequest({...request, payMethod: data});
        nextStep();
        break;

      case 9:
        setRequest({...request, title: data.title, attendees: data.attendees, name: data.title, email: data.email, phone: data.phone, info: data.info, address: data.address, city: data.city});
        nextStep();
        break;

      case 10:
        if(data.id){
          setRequest(data)
          nextStep();}
        else
          skipNextStep();
        break;

      case 11:
        setSelectedPackage(false);
        break;

      default:
        setSelectedPackage(false);
    }
  }

  return (
    <div style={{overflow: 'hidden', minHeight: '750px'}} ref={ref}>
      {place ?
        <Book
          place={place}
          request={request}
          step={step}
          maxStep={maxStep}
          onHandleStep={onHandleStep}
          prevStep={() => prevStep()}
          error={error} /> :
        <StepError />
      }
    </div>
  )
}

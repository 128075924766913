import React, { useState, useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import axios from 'axios';
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid';
import { Text } from '../../../components/ui-kit/Typo'

export default function Locations(props){

  let [ places, setPlaces ] = useState([]);
  let { lang } = useParams();
  const { t } = useTranslation('listing');

  useEffect(() => {
    axios.post(process.env.REACT_APP_BUDDY_SERVICE + `/explore/places`, {eventType: [], locations:[]})
    .then(response => setPlaces(response.data));
  }, [])

  return (
    <Grid>
      <Text type="subtitle2">România</Text>
      <Grid container spacing={1} justify="center" >

          {places.map((place, key) =>
            <Grid item xs={11} key={key}>
              <NavLink to={location => `/${lang}/${place.link}`}>
                <Text type="subtitle3" semibold key={key}>{place.name}</Text>
              </NavLink>
              <Text type="body"><span className="capitalize">{`${place.placeInfo.activities.map((a) => ` ${t(a + '')}`)}`}</span>
                                {place.placeInfo.locations.map((l) => <span> | {l.name}, {l.county.name}, {l.county.country.name} </span>)}</Text>
            </Grid>
          )}
      </Grid>

    </Grid>
  )
}

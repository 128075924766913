import React, { useState } from 'react'
import { Grid, Button } from '@material-ui/core'
import { Text } from '../../components/ui-kit/Typo'
import { makeStyles } from '@material-ui/core/styles'
import { NavLink, useParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '10px',
        "&:hover": {
            backgroundColor: theme.palette.action.hover
        }
    },
    btn: {
      margin: 'auto 0',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end'
      },
    }
}));

export default function PkgDetails({ place, pkg }) {

    const { t } = useTranslation('listing');
    const classes = useStyles();
    let { lang, packageLink } = useParams();

    return (
      <Grid item xs={12}>
        {place && <Grid container className={classes.root} spacing={3}>
          <Grid item xs={12} md={10}>
            <Grid container  spacing={0}>
              <Grid item xs={10} md={10}>
                  <Text type="subtitle3" component="h3" semibold className="sb-blue2">
                      {pkg.title}
                  </Text>
              </Grid>
              <Grid item xs={12}>
                <Text type="body1" component="h4" className="sb-text body1">
                  {pkg.type.length === 2 ? t('profile.pakage.lessons_both') : pkg.type.indexOf('snowboard') > 0 ? t('profile.pakage.lessons_snow') : t('profile.pakage.lessons_ski')}
                  {pkg.spots} {pkg.spots === 1 ? t('profile.pakage.person') : t('profile.pakage.persons')}
                  {pkg.availability} {pkg.availability === 1 ? t('profile.pakage.lesson') : t('profile.pakage.lessons')}
                  {pkg.timeUnit} {pkg.timeUnit === 1 ? t('profile.pakage.hour') : t('profile.pakage.hours')}
                  <br/>
                  {t('profile.pakage.location')}: {pkg.locations.map((l, i) => {return `${l.name}, ${l.county.name} ${pkg.locations.length > 1 && i+1 < pkg.locations.length ? ' / ' : ''}`})}
                  <br/>
                  {(pkg.price > 0 &&
                      <>
                          {t('profile.pakage.price')}
                          <span className="sb-text regular">{pkg.price} {place.placeInfo.currency}{pkg.pkgPrice ? t('profile.pakage.price_pkg') : t('profile.pakage.price_pers')}</span>
                      </>
                  )}
                </Text>
              </Grid>
              <Grid item md={12}>
                  <Text type="body" component="p" >{pkg.abs && `"`}{pkg.abs}</Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={'auto'} md={2} className={classes.btn}>
            <Grid container direction="row" alignItems="center" justifyContent="flex-end">
              <Grid>
                <NavLink to={location => `/${lang}/${place.link}/${pkg.link}`}>
                  <Button variant="outlined" color="primary">
                    {t('profile.pakage.details')}
                  </Button>
                </NavLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>}
      </Grid>
    )
}

import React, { useState, useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import axios from 'axios';
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid';
import { Text } from '../../components/ui-kit/Typo'
import { Papir } from '../../components/ui-kit/Papir';
import PkgType from './PkgType'
import Seo from '../../components/general/Seo'

import './listing.scss'

export default function Locations(props){

  let [ locatione, setLocatione ] = useState({county: {country: {}}}) // current location from path as enum
  let [ places, setPlaces ] = useState([]); // list of places from location param
  let [ packages, setPackages ] = useState([]); // list of special packages from location
  let { lang, location } = useParams();
  const { t } = useTranslation('listing');

  // get places from location
  useEffect(() => {
    axios.post(process.env.REACT_APP_BUDDY_SERVICE + `/explore/places`, {eventType: [], locations:[location]})
    .then(response => setPlaces(response.data));
  }, [location])

  // get location as enum
  useEffect(() => {
    axios.get(process.env.REACT_APP_BUDDY_SERVICE + `/explore/` + location)
    .then(response => setLocatione(response.data), err => window.location.replace("/404"));
  }, [location])

  // get courses, camps and experiences from location
  useEffect(() => {
    axios.post(process.env.REACT_APP_BUDDY_SERVICE + `/explore/packages`,
        { locations: location ? [location] : [],
          eventType: [],
          pkgType: ['COURSE', 'CAMP', 'EXPERIENCE']})
        .then(response => setPackages(response.data.packages));
  }, [location])

  const havePackage = type => { return packages.some(pkg => pkg.pkgType === type) }

  return (
    <Grid container spacing={5}>
      <Seo title={t('listing.meta.title', {name: locatione.name })}
         description={t('listing.description', {name: locatione.name, county: locatione.county.name, country: locatione.county.country.name })} />

      <Grid item xs={12}>
        <Text type="title2" className="sb-red1" bold>{t('listing.title', {name: locatione.name})}</Text>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3} justify="center" >

            {places.map((place, key) =>
              <Grid item xs={12} key={key}>
                <Grid container spacing={3} direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <NavLink to={location => `/${lang}/${place.link}`}>
                      <Papir className="white logo-wrap">
                        <img style={{ width: '45px'}}
                            src={`${process.env.REACT_APP_MAPI_URL}/public/images/${place.link}-logo`} />
                      </Papir>
                    </NavLink>
                  </Grid>
                  <Grid item xs={10}>
                    <NavLink to={location => `/${lang}/${place.link}`}>
                      <Text type="subtitle3" semibold key={key}>{place.name}</Text>
                    </NavLink>
                    <Text type="body"><span className="capitalize">{`${place.placeInfo.activities.map((a) => ` ${t(a + '')}`)}`}</span></Text>
                    <Text type="body">{place.placeInfo.locations.map((l, i) => {return `${l.name}, ${l.county.name} ${place.placeInfo.locations.length > 1 && i+1 < place.placeInfo.locations.length ? ' / ' : ''}`})}</Text>
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Grid>
      </Grid>

      {havePackage('COURSE') && places.length > 0 && packages.length > 0 && <Grid item xs={12}>
      <PkgType places={places} packages={packages} type={'COURSE'} />
      </Grid>}

      {havePackage('CAMP') && places.length > 0 && packages.length > 0 && <Grid item xs={12}>
        <PkgType places={places} packages={packages} type={'CAMP'} />
      </Grid>}

      {havePackage('EXPERIENCE') && places.length > 0 && packages.length > 0 && <Grid item xs={12}>
        <PkgType places={places} packages={packages} type={'EXPERIENCE'} />
      </Grid>}

      { false && <Grid item xs={12}>
        <Text type="subtitle2">{t('listing.about', {name: locatione.name, county: locatione.county.name, country: locatione.county.country.name})}</Text>
      </Grid>}

    </Grid>
  )
}

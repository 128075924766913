import React from 'react'
import { NavLink } from 'react-router-dom'
import { useParams } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import { Text } from '../../components/ui-kit/Typo'
import Seo from '../../components/general/Seo'

import Locations from '../listing/list/Locations';

import logoComplex from './assets/logo-complex-new.png'
import experiences from '../../assets/img/experiences/main.jpg'
import camps from '../../assets/img/camps/main.png'
import kids from '../../assets/img/kids/main.jpg'

export default function HomePage(props){

  let { lang } = useParams();
  let {searchDto} = props;
  return (
    <Grid container spacing={5} justify="flex-start" >

      <Seo title="SnowBuddy.app | Ski and Snowboard School Management System"
           description="A software solution for the management of skiing and snowboarding with three main components: the platform, for skiing school staff, the app for instructors and a reservation form for clients."
           image={logoComplex} />

      <Grid item xs={12}>
        <Text type="subtitle" className={'sb-blue2'}>Cold?</Text>
        <NavLink to={location => `/${location.pathname.split('/')[1]}/getabook`}>
          <Text type="subtitle">Get a book</Text>
        </NavLink>
        <Text type="subtitle">You'll still be cold but you'll have a book.</Text>
      </Grid>
      <Grid item xs={12}>
        <Text type="subtitle" className={'sb-blue2'}>Wanna learn skiing or snowboarding?</Text>
        <NavLink to={location => `/${lang}/explore/ro`}>
          <Text type="subtitle">Surf here</Text>
        </NavLink>
      </Grid>
      <Grid item xs={12}>
        <Locations />
      </Grid>
      <Grid item xs={12}>
        <Text type="subtitle" className={'sb-blue2'}>Wanna something else?</Text>
      </Grid>
      <Grid item xs={12} md={12}>
        <NavLink to={location => `/${lang}/baking`}>
          <Text type="subtitle">Kids Camps and Courses</Text>
          <img src={kids} alt="Kids" style={{maxWidth: '100%', borderRadius: '15px'}}/>
        </NavLink>
      </Grid>
      <Grid item xs={12} md={6}>
        <NavLink to={location => `/${lang}/baking`}>
          <Text type="subtitle">Experiences</Text>
          <img src={experiences} alt="Experiences" style={{maxWidth: '100%', borderRadius: '15px'}}/>
        </NavLink>
      </Grid>
      <Grid item xs={12} md={6}>
        <NavLink to={location => `/${lang}/baking`}>
          <Text type="subtitle">Camps</Text>
          <img src={camps} alt="Camps" style={{maxWidth: '100%', borderRadius: '15px'}}/>
        </NavLink>
      </Grid>
      <Grid item xs={12} md={12}>
        <Text type="subtitle" className={'sb-blue2'}>Wanna more?</Text>
        <Text type="subtitle2">More info's <NavLink to={location => `/${location.pathname.split('/')[1]}/about`}>about</NavLink> the .app</Text>
      </Grid>
      {/*<Grid item xs={12} md={12}>
        <Text type="subtitle" className={'sb-blue2'}>Wanna join?</Text>
        <Text type="subtitle2"><a href="https://iservit.ro" title="iServit | Secude, Deploy, Scale, Repeat">Apply</a> for the summer of '23 internship</Text>
        <Text type="body">We have two open positions for this summer, one for your creative idea about the SnowBuddy app and one for frontend development with React</Text>
      </Grid>*/}
      <Grid item xs={12} md={6}>
          <Text type="subtitle3">#enjoytheride 🤟</Text>
      </Grid>
    </Grid>
  )
}
// <img src={logo1Dec} alt="Logo 1 Decembrie" style={{maxWidth: '100%', borderRadius: '15px'}}/>

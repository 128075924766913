import React from 'react'
import { animated } from 'react-spring'

import Grid from '@material-ui/core/Grid';
import { Papir } from '../../../components/ui-kit/Papir'
import { Text } from '../../../components/ui-kit/Typo'

import { ReactComponent as BeginnerIcon } from './assets/beginner.svg'
import { ReactComponent as IntermediateIcon } from './assets/intermediate.svg'
import { ReactComponent as AdvancedIcon } from './assets/advanced.svg'

import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    svgicon: {
      width: '100px',
      height: '100px',
      fill: theme.palette.text.secondary,
      [theme.breakpoints.down('xs')]: {
        width: '75px',
        height: '75px'
      }
    },
    active: {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main
    }
  }));

export function Step5(props){

  let {style, onHandleStep, place, request} = props;
  const classes = useStyles();

  const { t } = useTranslation('iframe');

  return(
    <animated.div style={{ ...style, padding: '16px' }}>
      <Grid container
        spacing={3}
        justify="space-around"
        alignItems="center">
        <Grid item md={4}>
          <Text type="subtitle" uppercase>{t('event.book.step2.experience')}</Text>
        </Grid>
        <Grid item md={8} xs={12}>
          <Grid container
            spacing={1}
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item style={{cursor: 'pointer'}} onClick={() => onHandleStep('BEGINNER')} md={4} xs={12}>
              <Papir align="center">
                <BeginnerIcon className={`${request.level === 'BEGINNER' && classes.active} ${classes.svgicon}`} />
                <Text type="subtitle3">{t('event.book.step2.beginner')}</Text>
              </Papir>
            </Grid>
            <Grid item style={{cursor: 'pointer'}} onClick={() => onHandleStep('INTERMEDIATE')} md={4} xs={6}>
              <Papir align="center">
                <IntermediateIcon className={`${request.level === 'INTERMEDIATE' && classes.active} ${classes.svgicon}`} />
                <Text type="subtitle3">{t('event.book.step2.intermediate')}</Text>
              </Papir>
            </Grid>
            <Grid item style={{cursor: 'pointer'}} onClick={() => onHandleStep('ADVANCED')} md={4} xs={6}>
              <Papir align="center">
                <AdvancedIcon className={`${request.level === 'ADVANCED' && classes.active} ${classes.svgicon}`} />
                <Text type="subtitle3">{t('event.book.step2.advanced')}</Text>
              </Papir>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </animated.div>
  )
}

import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios'

import PlacePackage from './PlacePackage'
import Info from './Info'
import PkgSimple from './PkgSimple'
import PkgCourse from './PkgCourse'
import PkgCamp from './PkgCamp'
import PkgExperience from './PkgExperience'
import PlaceHeader from './PlaceHeader'
import PlaceFooter from './PlaceFooter'
import Seo from '../../../components/general/Seo'

import { Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next'
import { Route, Switch as RoutingSwitch } from 'react-router-dom'

import { useTransition } from 'react-spring'

export default function Place(props) {

  const { t } = useTranslation('listing');
  let { placeLink, lang } = useParams();

  const [place, setPlace] = useState(null);

  useEffect(() => {
    axios.get(process.env.REACT_APP_BUDDY_SERVICE + `/place/${placeLink}`)
        .then(response => {
          setPlace(response.data);
        })
        .catch(error => {
          window.location.replace(`/${lang}/404`);
        });
  }, [placeLink]);

  const seoTitle = () => {
    let title = `${place.name} - `;
    // if (place.placeInfo.activities.includes('ski') && place.placeInfo.activities.includes('snowboard') && place.placeInfo.activities.includes('kids'))
    //   title += t('profile.meta.title_both_kids')
    // else if (place.placeInfo.activities.includes('ski') && place.placeInfo.activities.includes('kids'))
    //   title += t('profile.meta.title_ski_kids')
    // else if (place.placeInfo.activities.includes('snowboard') && place.placeInfo.activities.includes('kids'))
    //   title += t('profile.meta.title_snowboard_kids')
    // else
    if (place.placeInfo.activities.includes('snowboard') && place.placeInfo.activities.includes('ski'))
      title += t('profile.meta.title_both')
    else if (place.placeInfo.activities.includes('ski'))
      title += t('profile.meta.title_ski')
    else if (place.placeInfo.activities.includes('snowboard'))
      title += t('profile.meta.title_snowboard')
    title += `${place.placeInfo.locations.map((l, i) => ` ${l.name}`)}`
    return title;
  }

  return (
    <div>
      {place ?
        <Grid container spacing={5}>
          <Seo title={seoTitle()}
             description={place.placeInfo.descr}
             image={`/buddy/public/images/${place.link}-logo`} />

          <Grid item xs={12}>
            <PlaceHeader place={place} />
          </Grid>

          <RoutingSwitch>
            <Route exact path={`/:lang(en|ro|de)/:placeLink`} render={() => (<PlaceMain place={place}/>)} />
            <Route exact path={`/:lang(en|ro|de)/:placeLink/:packageLink`} render={() => (<PlacePackage place={place}/>)} />
          </RoutingSwitch>

          <Grid item xs={12}>
            <PlaceFooter place={place} />
          </Grid>

        </Grid> :
        <Grid container >
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress color="secondary" />
          </Grid>
        </Grid>
      }
    </div >
  )
}

const PlaceMain = ({place}) => {

  const [displayInfo, setDisplayInfo] = useState(false);

  const toggleDisplayInfo = () => {
    setDisplayInfo(!displayInfo)
  }

  const havePackage = type => {
    for (var key in place.placePakage) {if (place.placePakage[key].pkgType === type) return true}}

  const transitionInfo = useTransition(displayInfo, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 }
  })

  const transitionPackages = useTransition([1], null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 }
  })

  return (
    <Grid item xs={12}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Info info={place}></Info>
        </Grid>

        {havePackage('SIMPLE') && <Grid item xs={12}>
          <PkgSimple place={place} />
        </Grid>}

        {havePackage('COURSE') && <Grid item xs={12}>
          <PkgCourse place={place} />
        </Grid>}

        {havePackage('CAMP') && <Grid item xs={12}>
          <PkgCamp place={place} />
        </Grid>}

        {havePackage('EXPERIENCE') && <Grid item xs={12}>
          <PkgExperience place={place} />
        </Grid>}
      </Grid>
    </Grid>
  )
}

//${place.placeInfo.location.name}, ${place.placeInfo.location.county.name}
